import { createRouter, createWebHistory } from 'vue-router'

/* Common routes start */
import NotFound from '../pages/GeneralViews/NotFoundPage.vue'
import DashboardLayout from '../pages/Dashboard/Layout/DashboardLayout.vue'
import AuthLayout from '../pages/Dashboard/Pages/AuthLayout.vue'
import Content from '../pages/Dashboard/Layout/Content.vue'
import DefaultHeader from '../pages/Dashboard/DefaultHeader.vue'
import Dashboard from '../pages/Dashboard/Dashboard/Dashboard.vue'

import EditProfileForm from '../pages/Dashboard/Pages/UserProfile/EditProfileForm.vue'
import UserCard from '../pages/Dashboard/Pages/UserProfile/UserCard.vue'
/* Common routes end */

/* Admin routes start */
import UsersViewAll from '../pages/Dashboard/Pages/Users/UsersViewAll.vue'
import UsersViewSingle from '../pages/Dashboard/Pages/Users/UsersViewSingle.vue'
import UsersCreate from '../pages/Dashboard/Pages/Users/UsersCreate.vue'
import UsersEdit from '../pages/Dashboard/Pages/Users/UsersEdit.vue'

import Home from '../pages/Dashboard/Pages/Home.vue'
import Ping from '../pages/Auth/Ping.vue'
import VerifyEmail from '../pages/Auth/VerifyEmail.vue'
import Logout from '../pages/Auth/Logout.vue'
import Terms from '../pages/Dashboard/Pages/TermsConditions.vue'
import Privacy from '../pages/Dashboard/Pages/PrivacyPolicy.vue'
import AboutUs from '../pages/Dashboard/Pages/AboutUs.vue'
import LearnMore from '../pages/Dashboard/Pages/LearnMore.vue'
import Investors from '../pages/Dashboard/Pages/Investors.vue'

import Register from "../pages/Auth/Vnud-Register.vue";
import Login from "../pages/Auth/Vnud-Login.vue";
import ForgotPassword from "../pages/Auth/Vnud-Forgot-Password.vue";
import ResetPassword from "../pages/Auth/reset-password.vue";

/* Admin routes end */

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresLogin: false },
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'Home index',
        component: Home
      }
    ]
  },
  {
    path: '/ping',
    name: 'Ping',
    meta: { requiresLogin: false },
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'Ping index',
        component: Ping
      }
    ]
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    meta: { requiresLogin: false },
    component: Content,
    children: [
      {
        path: '',
        name: 'Privacy index',
        component: Privacy
      }
    ]
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms',
    meta: { requiresLogin: false },
    component: Content,
    children: [
      {
        path: '',
        name: 'Terms index',
        component: Terms
      }
    ]
  },
  {
    path: '/learn-more',
    name: 'LearnMore',
    meta: { requiresLogin: false },
    component: Content,
    children: [
      {
        path: '',
        name: 'LearnMore index',
        component: LearnMore
      }
    ]
  },
  {
    path: '/investors',
    name: 'Investors',
    meta: { requiresLogin: false },
    component: Content,
    children: [
      {
        path: '',
        name: 'Investors index',
        component: Investors
      }
    ]
  },
  {
    path: '/about',
    name: 'AboutUs',
    meta: { requiresLogin: false },
    component: Content,
    children: [
      {
        path: '',
        name: 'AboutUs index',
        component: AboutUs
      }
    ]
  },
  {
    path: '/auth/',
    name: 'Auth',
    meta: { requiresLogin: false },
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'Login',
        components: { default: Login, header: DefaultHeader }
      },
      {
        path: "register",
        name: "Register",
        component: Register,
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        component: ResetPassword,
      },
      {
        path: 'verify-email',
        name: 'VerifyEmail',
        components: { default: VerifyEmail, header: DefaultHeader }
      },

      {
        path: 'logout',
        name: 'Logout',
        components: { default: Logout, header: DefaultHeader }
      }
    ]
  },
  {
    path: '/',
    meta: { requiresLogin: true },
    component: DashboardLayout,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard, header: DefaultHeader }
      },
      {
        path: 'users',
        name: 'View Users',
        components: { default: UsersViewAll, header: DefaultHeader }
      },
      {
        path: 'users/new',
        name: 'Create User',
        components: { default: UsersCreate, header: DefaultHeader }
      },
      {
        path: 'users/:userId',
        name: 'View User',
        components: { default: UsersViewSingle, header: DefaultHeader }
      },
      {
        path: 'users/:userId/edit',
        name: 'Edit User',
        components: { default: UsersEdit, header: DefaultHeader }
      },      
      {
        path: 'profile',
        name: 'Profile',
        components: { default: UserCard, header: DefaultHeader }
      },
      {
        path: 'profile/edit',
        name: 'Edit Profile',
        components: { default: EditProfileForm, header: DefaultHeader }
      },
      {
        path: 'settings',
        name: 'Settings',
        components: { default: Dashboard, header: DefaultHeader }
      },      
      
    ]
  },
  {
    path: '/:catchAll(.*)', // Use catchAll for 404 page
    name: 'NotFound',
    component: AuthLayout,
    children: [
      {
        path: '',
        component: NotFound
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})

export default router
