<template>
  <div class="px-4 d-flex flex-column h-screen">
    <div class="bg-white login-warpper mx-auto w-100">
      <div class="login-header text-center position-relative pa-4 overflow-hidden">
        <img src="https://instaevent.imgix.net/files/instaevent-logo-large.png?w=125" class="mx-auto" width="58" cover />
        <h3 class="font-weight-regular">
          Reset your password
        </h3>
      </div>
      <div class="login-tab">
        <form>
          <div class="pa-4">
            <div class="login-form">
              <alert :error-bag="errorBag" />
              <v-alert v-if="hasMessage" type="success">
                <div>{{ successMsgBag['status'] }}</div>
               </v-alert>
            </div>

            <v-text-field
              placeholder="New Password"
              prepend-inner-icon="mdi-lock"
              variant="solo"
              hide-details="auto"
              class="login-field mb-3"
              v-model="signupForm.password"
              type="password"
              @blur="v$.password.$touch"
              @input="v$.password.$touch"
              :error-messages="v$.password.$errors.map((e) => e.$message)"
            ></v-text-field>

            <v-text-field
              placeholder="Confirm New Password"
              prepend-inner-icon="mdi-lock"
              variant="solo"
              hide-details="auto"
              class="login-field"
              type="password"
              v-model="signupForm.password_confirmation"
              @blur="v$.password_confirmation.$touch"
              @input="v$.password_confirmation.$touch"
              :error-messages="v$.password_confirmation.$errors.map((e) => e.$message)"
            ></v-text-field>
          </div>
          <v-btn
            height="70"
            color="primary"
            class="rounded-0 w-100 text-uppercase"
            @click="handleSubmit"
          >
            Submit
          </v-btn>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, computed, inject } from 'vue';
import { useRoute } from 'vue-router'
import { apiPost } from '@/utils/api'

const route = useRoute();

const customError = inject('customError');
const successMsgBag = customError.successMsgBag;

const hasMessage = computed(() => {
  return Object.keys(successMsgBag).length > 0;
});

const signupForm = reactive({
  code:'',
  password: '',
  confirm_password: ''
})
const signupFormRules = {
  password: { required },
  password_confirmation: { required },
};

if (route.query.code) {
  signupForm.code = route.query.code
}

const v$ = useVuelidate(signupFormRules, signupForm);

const handleSubmit = async () => {
  const result = await v$.value.$validate();
  if (result) {
    try {
      customError.resetErrorBag();
      const response = await apiPost('/auth/reset-password', signupForm);
      if (response.success) {
        customError.transformSuccessMsg(response);
      }
    } catch (error) {
      customError.transformValidationErrors(error.response);
    }
  }
};
</script>
