<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="stacked-form" title="Create Contact">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="card-title">
                Create User
              </h4>
              <div content="Go Back" :open-delay="300" placement="top">
                <n-button type="info" size="sm" @click="backToUsers">
                  <img class="action-button-icon" style="cursor:pointer"
                    src="@/assets/img/glyphicons-basic-309-square-empty-left.png" />
                </n-button>
              </div>
            </div>
          </template>
          <Form @submit="createContact" :validation-schema="schema">
            <Alert :error-bag="customError.errorBag" class="mb-2" />
            <div>
              <vee-input label="Name" name="name" type="text" inputClasses="form-control-md" placeholder="Enter the contact's name" />
              <vee-input label="Email" name="email" type="text" inputClasses="form-control-md" placeholder="Enter the contact's email" />
              <vee-input label="Phone" name="phone" type="text" inputClasses="form-control-md" placeholder="Enter the contact's phone" />
              <n-button native-type="submit" type="primary" class="text-white">Submit</n-button>
            </div>
          </Form>
        </vnud-card>
        <UploadCSV />
      </div>
    </div>
    <modal v-model:show="showAlert" class="modal-primary" :show-close="false">
      <template v-slot:header>
        <div class="modal-profile d-flex justify-content-center align-items-center">
          <i class="now-ui-icons location_pin"></i>
        </div>
      </template>
      <p>{{ alertMessage }}</p>
      <template v-slot:footer>
        <n-button type="neutral" link="" @click="showAlert = false">Try again</n-button>
      </template>
    </modal>
  </div>
</template>
<script>
import {
  Modal,
  VnudCard,
  VeeInput
} from '@/components/index.js'
import UploadCSV from './UploadCSV.vue';
import { apiPost } from '@/utils/api'
import Alert from "@/components/Alert.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default {
  components: {
    Modal,
    VnudCard,
    UploadCSV,
    Alert,
    VeeInput,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
  },
  inject: ["customError"],
  data() {
    return {
      showAlert: false,
      alertMessage: '',
      schema : Yup.object().shape({
        name: Yup.string().required().label("The Name"),
        email: Yup.string().email().required().label("The Email"),
        phone: Yup.number().min(10).required().label("The Phone"),
      })
    }
  },
  mounted() {
    this.customError.resetErrorBag();
  },
  methods: {
    createContact: async function (values) {
      console.log(values);
      const userForm = values
      this.customError.resetErrorBag();
      if (!userForm.name || !userForm.email) {
        try {
          await apiPost('/contact', { name: userForm.name, email: userForm.email, phone: userForm.phone })
          this.$router.push('/users/')
        } catch (error) {
          this.customError.transformValidationErrors(error.response);
        }
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    backToUsers() {
      this.$router.push('/users')
    },
  }
}
</script>
<style scoped>
.action-button-icon {
  filter: invert(1);
  width: 20px;
}
</style>
