<template>
  <div class="col-lg-4 col-md-6 col-sm-8 col-12 ml-auto mr-auto">
    <vnud-card class="card-login card-plain">
      <template v-slot:header>
        <div>
          <div class="logo-container text-center">
            <img src="https://instaevent.imgix.net/files/instaevent-logo-large.png?w=125" alt="" />
          </div>
        </div>
      </template>
      <div class="login-form text-center">
        <!-- <v-btn class="w-100 google-btn text-capitalize" @click="navigateToGoogleSignIn" variant="outlined">
            Sign in with Google
          </v-btn> -->
        <button type="button" @click="navigateToGoogleSignIn" class="gsi-material-button">
          <div class="gsi-material-button-state"></div>
          <div class="gsi-material-button-content-wrapper">
            <div class="gsi-material-button-icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
                xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                <path fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z">
                </path>
                <path fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z">
                </path>
                <path fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z">
                </path>
                <path fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
                </path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </div>
            <span class="gsi-material-button-contents">Sign in with Google</span>
            <span style="display: none;">Sign in with Google</span>
          </div>
        </button>        
      </div>
      <span class="py-4 d-block text-center or-text">or</span>
      <Alert :error-bag="customError.errorBag" class="mb-2" />
      <Form @submit="handleSubmit" :validation-schema="schema">
        <div>
          <vee-input name="email" type="text" inputClasses="no-border form-control-lg" placeholder="Email"
            addon-left-icon="now-ui-icons ui-1_email-85" />

          <vee-input name="password" type="password" inputClasses="no-border form-control-lg" placeholder="Password"
            addon-left-icon="now-ui-icons ui-1_lock-circle-open" />

          <div class="pt-2">
            <n-button native-type="submit" type="primary" class="mb-2" round block>
              Sign In
            </n-button>
            <div class="pull-left">
              <h6>
                <router-link class="link footer-link" to="/auth/register">
                  Create Account
                </router-link>
              </h6>
            </div>

            <div class="pull-right">
              <h6>
                <a href="/auth/forgot-password" class="link footer-link">Forgot Password ?</a>
              </h6>
            </div>
          </div>
        </div>
      </Form>
    </vnud-card>
  </div>
</template>
<script setup>
import { VeeInput } from "@/components";
import { Form } from "vee-validate";
import * as Yup from "yup";

import { inject, ref } from "vue";
import { setToken, setApiUser, unsetToken } from '@/utils/auth'
import { apiPost, apiGet } from '@/utils/api'

import { useRoute, useRouter } from 'vue-router';
const customError = inject('customError');
import Alert from "@/components/Alert.vue";

const navigateToGoogleSignIn = () => {
  window.location.href = `${import.meta.env.VITE_APP_API_BASE_URL}/auth/google/?client=admin`;
};

const schema = Yup.object().shape({
  email: Yup.string().email().required().label("The Email"),
  password: Yup.string().min(6).required().label("The Password"),
});

const route = useRoute();
const router = useRouter();

const loading = ref(false);

const handleSubmit = async (values) => {
  const loginForm = values
  if (loginForm) {
    try {
      customError.resetErrorBag();
      const login = await apiPost('/auth/login', {
        email: loginForm.email,
        password: loginForm.password,
        client: 'admin'
      });

      if (login.success) {
        try {
          setToken(login.data.token)
          let user = await apiGet('/admin/user')          
          setApiUser(user);
          setTimeout(() => {
            router.push('/dashboard');
          }, 1000);
        } catch (error) {      
          unsetToken()
        }        
      }
    } catch (error) {
      customError.transformValidationErrors(error.response);
    }
  }
};

const setTokenValue = async (token) => {
  try {
    try {
        setToken(token)
        let user = await apiGet('/admin/user')    
        setApiUser(user);
        setTimeout(() => {
          router.push('/dashboard');
        }, 1000);
      } catch (error) {      
        unsetToken()
      }     
    loading.value = false;
    setTimeout(() => {
      router.push('/dashboard');
    }, 1000);;
  } catch (error) {
    loading.value = false;
    console.log(error);
  }
}

if (route.query.code) {
  loading.value = true;
  setTokenValue(route.query.code)
}

</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
<style scoped>
.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}
</style>