<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="card-chart text-center cardCenter" subTitle="" description="" no-footer-line>
          <h4>Main Menu</h4>
          <div style="clear:both">
            <div style="float: left; margin:30px;text-align:center;">
              <img style="cursor:pointer" @click="goUsers" width="80" src="@/assets/img/004-friends.svg" />
              <br>
              <strong>Users</strong>
            </div>
          </div>
        </vnud-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'dashboard-page',
  methods: {
    goUsers() {
      this.$router.push('/users/')
    }
  }
}
</script>
<style>
.cardCenter div {
  display: table;
  margin: 0px auto;
}
</style>
