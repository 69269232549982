<template>
  <div>
    <div class="row">
      <div class="col-xl-6 col-sm-12">
        <vnud-card card-body-classes="table-full-width" no-footer-line>
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <h4 class="card-title">Users</h4>
                <div>{{ 'Found ' + users.length + ' users' }}</div>
              </div>
              <fg-input class="">
                <el-input type="search" class="mb-3 input-with-icon" clearable  style="width: 200px"
                  placeholder="Search records" v-model="searchQuery" aria-controls="datatables">
                  <template #prefix>
                    <el-icon class="el-input__icon"><search /></el-icon>
                  </template>
                </el-input>
              </fg-input>
            </div>            
          </template>
          <el-table stripe style="width: 100%" :data="queriedData">
            <el-table-column min-width="190" prop="name" label="Name" />
            <el-table-column min-width="190" prop="email" label="Email" />
            <el-table-column min-width="190" header-align="right" label="Actions">
              <template v-slot="{ row }">
                <div class="text-right table-actions">
                  <el-tooltip content="View" :open-delay="300" placement="top">
                    <n-button type="info" size="sm" @click="goToUser(row.id)">
                      <i class="fa-regular fa-eye text-white"></i>
                    </n-button>
                  </el-tooltip>
                  <el-tooltip content="Edit" :open-delay="300" placement="top">
                    <n-button type="info" size="sm" @click="editUser(row.id)">
                      <i class="fa-solid fa-pen-to-square text-white"></i>
                    </n-button>
                  </el-tooltip>
                  <el-tooltip :content="row.banned ? 'Suspended' : 'Suspend'" :open-delay="300" placement="top">
                    <n-button :type="row.banned ? 'danger' : 'info'" size="sm" @click="suspendUser(row.id, row.banned)">
                      <i class="fa-solid fa-ban"></i>
                    </n-button>
                  </el-tooltip>
                  <el-tooltip content="Delete" :open-delay="300" placement="top">
                    <n-button type="info" size="sm" @click="deleteUser(row.id)">
                      <i class="fa-solid fa-rectangle-xmark text-white"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <template v-slot:footer>
            <n-pagination class="pagination-no-border" :total="users.length" :perPage="perPage"
              v-model="userPage" />
            <div class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="d-flex align-items-center">
                <span class="mr-2">User per page: </span>
                <el-select class="per-page-selector" placeholder="User per page" v-model="perPage">
                  <el-option v-for="option in perPageOptions" :value="option" :label="option" :key="option" />
                </el-select>
              </div>
              <el-tooltip content="New User" :open-delay="300" placement="top">
                <n-button type="primary" round icon="" size="lg" style="float: right;" @click="addUser">
                  <i class="now-ui-icons ui-1_simple-add text-white"></i>
                </n-button>
              </el-tooltip>
            </div>
          </template>
        </vnud-card>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGet, apiPost, apiPut } from '@/utils/api'
import { Pagination as NPagination, VnudCard } from '@/components/index.js'
import { Search } from '@element-plus/icons-vue'
export default {
  components: {
    VnudCard,
    NPagination,
    Search
  },
  data() {
    return {
      users: [],
      userPage: 1,
      perPage: 30,
      perPageOptions: [5, 10, 30, 50],
      searchQuery: "",
      propsToSearch: ["name"],
    }
  },
  async mounted() {
    // Fetch the users once the component is mounted    
    this.getAllUsers()
  },
  methods: {
    async getAllUsers() {
      this.users = await apiGet('/admin/user/list-users')
    },
    // Navigate to the user details page
    goToUser(id) {
      this.$router.push('/users/' + id)
    },
    // Navigate to the add user page
    addUser() {
      this.$router.push('/users/new')
    },
    // Navigate to the edit user page
    editUser(id) {
      this.$router.push('/users/' + id + '/edit')
    },
    // Delete the user with confirmation
    async deleteUser(id) {
      const deleteUser = await this.$swal({
        title: 'Delete User?',
        text: 'Are you sure you would like to delete this user?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-success btn-fill',
        confirmButtonText: 'Yes, Delete User!',
        cancelButtonText: 'Keep User',
        buttonsStyling: false,
        customClass: {
          container: 'custom-swal',
          title: 'mb-0',
          icon: 'question-icon mt-0',
          cancelButton: 'my-0',
          confirmButton: 'my-0'
        }
      })
      if (deleteUser.value) {
        const response = await apiPut('/admin/user/' + id + '/delete')
        if (response) {
          this.getAllUsers()
        }
      }
    },
    async suspendUser(id, banned) {
      const bannedUser = await this.$swal({
        title: `${ banned ? 'Unsuspend' : 'Suspend' } User?`,
        text: `Are you sure you would like to ${ banned ? ' unsuspend ' : ' suspend ' } this user?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-success btn-fill',
        confirmButtonText: `Yes, ${ banned ? ' Unsuspend ' : ' Suspend ' } User!`,
        cancelButtonText: 'Keep User',
        buttonsStyling: false,
        customClass: {
          container: 'custom-swal',
          title: 'mb-0',
          icon: 'question-icon mt-0',
          cancelButton: 'my-0',
          confirmButton: 'my-0'
        }
      })
      if (bannedUser.value) {
        const response = await apiPut('/admin/user/' + id + `${ banned ? '/unBanned ' : '/banned ' }`)
        if (response) {
          this.getAllUsers()
        }
      }
    },
  },
  computed: {
    // Get the users for the current page
    pagedUsers() {
      const start = (this.userPage - 1) * this.perPage
      const end = start + this.perPage
      return this.users.slice(start, end)
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedUsers;
      }
      let result = this.users.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
  },
}
</script>

<style>
.per-page-selector {
  width: 100px !important;
}
.input-with-icon.el-input--prefix .el-input__inner {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 0 !important;
}
.input-with-icon .el-input__wrapper {
  border-radius: 50px !important;
}
.input-with-icon .el-input__inner:focus {
  border: 0 !important;
}
</style>
