<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="card-chart" title="User" no-footer-line>
          <template v-slot:header>
            <div class="card-title">
              <h4>User Info</h4>
              <div style="float: right; margin-top: -70px;">
                <div content="Go Back" :open-delay="300" placement="top">
                  <n-button type="info" size="sm" @click="backToUsers()">
                    <img class="action-button-icon" style="cursor:pointer"
                      src="@/assets/img/glyphicons-basic-309-square-empty-left.png" />
                  </n-button>
                </div>
              </div>
            </div>
          </template>
          <br>
          <div>
            <strong>Name:</strong> {{ user.name }}
          </div><br>
          <div>
            <strong>Email:</strong> {{ user.email }}
          </div><br>
          <div>
            <strong>Phone:</strong> {{ user.phone }}
          </div><br>
        </vnud-card>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGet } from '@/utils/api'
import { VnudCard } from '@/components/index.js'

export default {
  components: { VnudCard },
  data() {
    return {
      user: {
        name: '',
        email: '',
        phone: ''
      }
    }
  },
  async mounted() {
    this.user = await apiGet('/admin/user/' + this.$route.params.userId)
  },

  methods: {
    backToUsers: function () {
      this.$router.push('/users/')
    }
  },
}

</script>
<style scoped>
.action-button-icon {
  filter: invert(1);
  width: 20px;
}
</style>
